<template>
  <page>
    <div>
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>

      <div style="padding: 0 13px">
        <div class="cellItem underline f_flex f_a_c" v-for='(item, index) in list' :key='index'>
          <div class="avatar">
            <img class="img" :src="item.avatar" alt="">
          </div>
          <div :class='{hasRead: item.readStatus == 2, noRead: item.readStatus == 1}'>
            <div class="title">{{item.userName}}</div>
            <div class="subTitle">{{item.readStatus == 1 ? '未读' : '已读'}}</div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import api from '@/api/other'

export default {
  data: ()=> ({
    list: []
  }),
  methods: {
    getList() {
      const {postsId} = this.$route.query
      api.readDetailList({
        postsId: postsId
      }).then(res => {
        if (res.data) {
          this.list = res.data.readDetailUserList
        }
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>


<style lang="less" scoped>
.hasRead {
  color: #9B9B9B;
}
.noRead {
  color: #2D2D2D;
}
.cellItem {
  height: 58px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
  overflow: hidden;
  background: #fafafa;
}
.title {
  font-size: 16px;
  line-height: 22px;
}
.subTitle {
  font-size: 12px;
  line-height: 17px;
}
</style>